<template>
  <div class="card-box">
    <div class="head-wrapper">
      <div>
        <img
          src="@/assets/images/china_mobile.svg"
          class="carrier-img"
          v-if="carrierType === 'china_mobile'"
        >
        <img
          src="@/assets/images/china_unicom.svg"
          class="carrier-img"
          v-else-if="carrierType === 'china_unicom'"
        >
        <img
          src="@/assets/images/china_telecom.svg"
          class="carrier-img"
          v-else-if="carrierType === 'china_telecom'"
        >
        <img
          src="@/assets/images/china_broadnet.png"
          class="carrier-img"
          v-else-if="carrierType === 'china_broadnet'"
        >
        <font v-else-if="carrierType === 'international_carrier'">国际运营商</font>
      </div>

      <div>
        {{ data.length }} 件
      </div>
    </div>

    <div class="goods-wrapper">
      <a-skeleton
        active
        v-if="loading"
      />

      <div v-else-if="data.length > 0">
        <div
          class="goods-card iotplt-cursor-pointer"
          v-for="(product) in data"
          :key="product.id"
          @click="toNewItems(product.id)"
        >

          <div class="goods-card-head">
            <a-tooltip>
              <template slot="title">
                {{ product.name }}
              </template>
              <div class="goods-name">{{ product.name }}</div>
            </a-tooltip>
            <div class="goods-price">￥{{ product.distributor_price | formatFloat }}</div>
          </div>

          <div class="goods-card-content">
            <p>用户业务模式：{{ product.user_business_type }}</p>
            <p>套餐周期：{{ product.service_cycle }}</p>
            <p>套餐容量：{{ product.package_capacity }}</p>
            <p>语音：{{ product.voice_capacity }}</p>
          </div>

          <div class="favorable-prices">
            <a-divider />

            <a-row :gutter="24">
              <a-col :span="12">
                <div class="favorable-price-label">采购周期数量</div>
              </a-col>
              <a-col
                :span="12"
                class="text-right favorable-price-label"
              >
                套餐价格(元)
              </a-col>
            </a-row>

            <a-row
              :gutter="24"
              v-for="(price) in product.agents_product_favorable_prices"
              :key="price.cycles"
            >
              <a-col :span="12">
                {{ price.cycles }}
              </a-col>
              <a-col
                :span="12"
                class="text-right"
              >
                {{ price.distributor_price }}
              </a-col>
            </a-row>

            <a-row :gutter="24" :style="{ marginTop: '10px' }">
              <a-col :span="12" class="favorable-price-label">
                超额资费
              </a-col>
              <a-col
                :span="12"
                class="text-right"
              >
                {{ product.excess_distributor_price }}
              </a-col>
            </a-row>
          </div>
        </div>
      </div>

      <a-empty v-else />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgentPurchaseItemProductCarrierCard',
  props: {
    data: {
      type: Array,
      require: true,
      default() {
        return []
      }
    },
    carrierType: {
      type: String,
      require: true,
      default: 'china_mobile'
    },
    loading: {
      type: Boolean,
      require: true
    }
  },
  methods: {
    toNewItems(productId) {
      this.$router.push({
        name: 'new_agent_purchase_item',
        query: { goods_id: productId }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.card-box {
  background-color: #fff;
  padding: 0 10px 10px 0;
  height: calc(100vh - 260px);
  min-height: 400px;
  overflow: hidden;

  .head-wrapper {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }

  .goods-wrapper {
    height: calc(100% - 60px);
    padding: 10px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
    }

    .goods-card {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 10px;
      margin-bottom: 10px;
      height: 160px;
      overflow: hidden;
      transition: height 0.1s 0.8s, transform .3s;
      -webkit-transition: height 0.1s 0.8s, transform .3s;

      &:hover {
        height: 100%;
        transform: scale(1.01);
        -webkit-transform: scale(1.01);
        -ms-transform: scale(1.01);
        box-shadow: 0 5px 20px -6px @primary-color;
        border-color: @primary-color;

        .favorable-prices {
          opacity: 1;
        }
      }

      // 解决safari中高度未变更，却显示出来 favorable-prices 部分
      .favorable-prices {
        opacity: 0;
        transition: opacity 0.1s 0.8s;
        -webkit-transition: opacity 0.1s 0.8s;
      }

      .goods-card-head {
        display: flex;
        align-items: flex-start;;
        justify-content: space-between;

        .goods-name {
          font-weight: bold;
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .goods-price {
          font-weight: bold;
          color: @primary-color;
        }
      }

      .goods-card-content {
        padding: 20px 0 0;
        color: #8c8c8c;
        line-height: 14px;
      }
    }
  }

  .text-right {
    text-align: right;
  }

  .favorable-price-label {
    color: #8c8c8c;
  }
}
</style>
