<template>
  <div>
    <div class="iotplt-card-box">
      <search-agent-purchase-item-product @submit="submitSearch" />
    </div>

    <div>
      <a-row
        :gutter="24"
        style="padding-top: 15px"
      >
        <a-col
          :span="5"
        >
          <carrier-card
            :data="data.china_mobile || []"
            :carrier-type="'china_mobile'"
            :loading="loading"
          />
        </a-col>

        <a-col
          :span="5"
        >
          <carrier-card
            :data="data.china_unicom || []"
            :carrier-type="'china_unicom'"
            :loading="loading"
          />
        </a-col>

        <a-col
          :span="5"
        >
          <carrier-card
            :data="data.china_telecom || []"
            :carrier-type="'china_telecom'"
            :loading="loading"
          />
        </a-col>

        <a-col
          :span="5"
        >
          <carrier-card
            :data="data.china_broadnet || []"
            :carrier-type="'china_broadnet'"
            :loading="loading"
          />
        </a-col>

        <a-col
          :span="4"
        >
          <carrier-card
            :data="data.international_carrier || []"
            :carrier-type="'international_carrier'"
            :loading="loading"
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { findGoods } from '@/api/agents_product'
import SearchAgentPurchaseItemProduct from '@/views/agent_purchase_items/goods/Search'
import CarrierCard from '@/views/agent_purchase_items/goods/CarrierCard'

export default {
  name: 'AgentPurchaseItemProductList',
  components: {
    SearchAgentPurchaseItemProduct,
    CarrierCard
  },
  data() {
    return {
      loading: true,
      data: {},
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    submitSearch(search) {
      // 重置排序
      this.query = Object.assign({},
        this.$options.data().query,
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findGoods(
        Object.assign({ agent_id: this.$store.getters.userAgentId }, this.query)
      ).then((res) => {
        this.data = this.$lodash.groupBy(res.data, 'carrier_type')
        this.loading = false
      })
    }
  }
}
</script>

